import React from 'react'

import Layout from '../components/Layout'
import Header from '../components/Header'
import FormSimpleAjax from '../components/FormSimpleAjax'
import SEO from '../components/Seo'

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <Header />

    <div className="container skinny taCenter">
      <h1>Contact</h1>
      <p>Use the form below to get in touch.</p>

      <FormSimpleAjax />
    </div>
  </Layout>
)

export default ContactPage
